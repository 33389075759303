import { createMachine, assign } from 'xstate';
import Api from '@api';

const loginMachine = createMachine({
  id: 'loginMachine',
  initial: 'idle',
  context: {
    userId: null,
    IdToken: null,
    error: null,
  },
  states: {
    idle: {
      on: {
        SUBMIT: 'loading',
      },
    },
    loading: {
      invoke: {
        id: 'credentials',
        src: (ctx, event) => async (cb, onReceive) => {
          const { username, password } = event.data;

          try {
            const { IdToken, RefreshToken, ExpiresIn, userId, tenants, role, message, code } =
              await Api.login({
                username,
                password,
              });

            if (IdToken && userId && role.hasAnyWriteAccess) {
              const accessTenants = tenants.filter((item) => item.create && item.delete);

              cb({
                type: 'SUCCESS',
                data: {
                  IdToken,
                  RefreshToken,
                  ExpiresIn,
                  userId,
                  role,
                  tenants: accessTenants,
                },
              });
            }

            if (role && !role.hasAnyWriteAccess) {
              cb({
                type: 'FAIL',
                data: {
                  error: true,
                  message: 'You dont have the permissions to login!',
                  code,
                },
              });
            }

            if (message && (code === 400 || code === 409)) {
              cb({ type: 'FAIL', data: { error: true, message, code } });
            }
          } catch (error) {
            cb({ type: 'FAIL', data: error });
          }
        },
      },
      on: {
        SUCCESS: {
          target: 'success',
          actions: assign({
            userId: (ctx, event) => event.data.userId,
            IdToken: (ctx, event) => event.data.IdToken,
            RefreshToken: (ctx, event) => event.data.RefreshToken,
            tenants: (ctx, event) => event.data.tenants,
            error: () => false,
            message: () => null,
            code: () => null,
          }),
        },
        FAIL: {
          target: 'fail',
          actions: assign({
            error: (ctx, event) => event.data.error,
            message: (ctx, event) => event.data.message,
            code: (ctx, event) => event.data.code,
          }),
        },
      },
    },
    success: {
      type: 'final',
    },
    fail: {
      on: {
        SUBMIT: 'loading',
      },
    },
  },
});

export default loginMachine;
