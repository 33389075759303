import React from 'react';

import Login from '@components/organisms/login';
import Layout from '@components/hoc/layout';

const Index = () => (
  <Layout>
    <Login />
  </Layout>
);

export default Index;
