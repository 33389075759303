// TODO need to refactor login component and remove styled components
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { navigate, useLocation } from '@reach/router';
import { useMachine } from '@xstate/react';
import { Formik } from 'formik';
import * as yup from 'yup';
import Button from '@components/atoms/button';
import SEO from '@components/seo';
import { setToken, isLoggedIn, setTenant } from '@helpers';
import Logo from '@images/white-login-logo.png';
import loginMachine from './machine';
import { Error } from './styles';
import Icon from '@components/atoms/icon';

import * as styles from './login.module.scss';

const validationSchema = yup.object().shape({
  username: yup
    .string()
    .email('This field must be a valid email')
    .required('Email is a required field'),
  password: yup
    .string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is a required field'),
});

// OLD = '54a22888-533e-4a4c-8930-f0221b1b86a6';
// B2C: 4b406589-dd5f-46f8-87b3-8a55a66c5871
// B2B: 3f5c0a11-5172-4842-966b-faa5ce8d9610
// PROD c72db414-56c9-40fa-a42c-e832553bf885

const Login = () => {
  const [current, send] = useMachine(loginMachine);
  const [tenant, setTenantId] = useState('');
  const location = useLocation();
  // const allTenants = JSON.parse(getTenants());
  // const mainSpaces = ['Justt', 'Justt Business'];
  const { IdToken, RefreshToken, userId, ExpiresIn, tenants } = current.context;
  const [passwordShown, setPasswordShown] = useState(false);

  const envTenants = (val) => {
    if (val !== 3) {
      setTenantId('4b406589-dd5f-46f8-87b3-8a55a66c5871');
    } else {
      setTenantId('c72db414-56c9-40fa-a42c-e832553bf885');
    }
  };

  const presetEnvironment = () => {
    const basePath = location.origin;
    let environmentVar = null;

    const preselected = Cookies.get('env');
    if (preselected) {
      environmentVar = preselected;
    } else if (basePath.includes('dev-ccd') || basePath.includes('localhost:8000')) {
      environmentVar = 0;
    } else if (basePath.includes('test-ccd')) {
      environmentVar = 1;
    } else if (basePath.includes('preprod-ccd')) {
      environmentVar = 2;
    } else {
      environmentVar = 3;
    }

    Cookies.set('env', environmentVar, { expires: 365 });
    envTenants(Cookies.get(environmentVar));
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  useEffect(() => {
    presetEnvironment();
  }, []);

  useEffect(() => {
    if (current.matches('success')) {
      setToken(IdToken, RefreshToken, userId, tenants, ExpiresIn);
      const matchtenant = tenants.find((item) => item.tenantId === tenant);
      if (matchtenant) {
        setTenant(matchtenant.tenantId);
      } else {
        setTenant(tenants[0]?.tenantId);
      }
      navigate(`/app/`, { replace: true });
    }
    // eslint-disable-next-line
  }, [IdToken, RefreshToken, userId]);

  useEffect(() => {
    if (isLoggedIn()) {
      navigate(`/app/`, { replace: true });
    }
    // eslint-disable-next-line
  }, [IdToken, RefreshToken, userId]);

  return (
    <div className={styles.formContainer}>
      <div className={styles.header}>
        <span>
          © Justt AG — Beta 1.0. <br />
          Currently, registration is not available. Only invited users can log in.
        </span>
      </div>
      <SEO title="Login" />
      <Formik
        initialValues={{
          username: '',
          password: '',
        }}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={validationSchema}
        autoComplete="off"
        onSubmit={(values) => {
          send({ type: 'SUBMIT', data: values });
        }}
      >
        {({ values, handleChange, handleBlur, handleSubmit, errors }) => (
          <form method="POST" onSubmit={handleSubmit}>
            <div className={styles.logoContainer}>
              <img src={Logo} alt="Login Logo" />
            </div>
            <div className={styles.formWrapper}>
              <div className={styles.formHeader}>
                <h2>Login</h2>
              </div>
              <div className={styles.formBody}>
                <input
                  type="email"
                  placeholder="Email"
                  className={errors.username ? styles.hasError : styles.inputStyle}
                  name="username"
                  value={values.username}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Error color="red" fontSize={[2, 3]}>
                  {errors.username}
                </Error>

                <div
                  className={`
                    ${styles.passwordWrapper} 
                    ${passwordShown ? styles.shown : styles.hidden}
                  `}
                >
                  <input
                    type={passwordShown ? 'text' : 'password'}
                    placeholder="Password"
                    className={errors.username ? styles.hasError : styles.inputStyle}
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <Button defaultBtn onClick={togglePassword} type="button">
                    {passwordShown ? (
                      <Icon iconClass="show-password" fSize={3.5} />
                    ) : (
                      <Icon iconClass="hide-password" />
                    )}
                  </Button>
                </div>

                {current.matches('fail') && (
                  <Error color="red" fontSize={[2, 3]}>
                    {current.context.error && current.context.message}
                  </Error>
                )}
                <Error color="red" fontSize={[2, 3]}>
                  {errors.password}
                </Error>
              </div>
              <div className={styles.formFooter}>
                <Button btnClass="primary" greenColor type="submit">
                  {current.matches('loading') ? 'Authenticating' : 'Login'}
                </Button>
                <div className={styles.termsAndServices}>
                  <span>Disclaimer: by logging in you agree to our</span>
                  <a href="https://legal.justt.me/TermsOfService_de.html" target="_blank">
                    Terms and Services
                  </a>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default Login;
