import styled from 'styled-components';
import { color, typography, layout, space, border } from 'styled-system';

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100vw;
  height: 100vh;
  background-color: #f3f3f3;
`;

export const HeaderDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 15%;
  padding-right: 13%;
  width: 100%;
  ${layout}
  ${space}
`;

export const SvgDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FormWrapper = styled.div`
  border-radius: 20px;
  backdrop-filter: blur(30px);
  border: solid 1px #f7eeee;
  background-color: rgba(255, 255, 255, 0.65);
  margin: 0 52px;
  height: 505px;
  width: 437px;
  margin: 0;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 53px;
`;

export const Text = styled.h1`
  ${color}
  ${space}
  ${typography}
`;

export const Label = styled.span`
  ${color}
  ${typography}
  ${space}
  display: inline-block;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 15%;
  padding-right: 13%;
  margin-bottom: auto;
  margin-top: auto;
  ${layout}
  ${space}
`;

export const Header = styled.div``;
export const Body = styled.div`
  text-align: left;
  width: 100%;
`;
export const Footer = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 7.1rem;
  margin-bottom: 3.6rem;
`;

export const Input = styled.input`
  width: 100%;
  border-top: none;
  border-right: none;
  border-left: none;
  outline: none;
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #292929;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: #292929;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: #292929;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: #292929;
  }
  border-bottom: 1px solid #fff;
  background-color: transparent;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  ${typography}
  ${space}
  ${color}
  ${border}
`;

export const Error = styled.div`
  ${color}
  ${typography}
`;
